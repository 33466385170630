<!-- @Author: Yu_Bo -->
<template>
	<div class='role_manage'>
    <!-- 标题 -->
    <div class="vue_title">角色管理</div>
    <!--  -->
    <div class="role_main">
      <!--  -->
      <div class="main_condition">
        <div class="cond_left">
          <el-button type="primary"
            class="btnBgColor_blue_empty"
            @click="addEditBtn()"
            size="small" icon="el-icon-plus">新增</el-button>
          <el-input v-model="role_name" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="搜索角色名称"></el-input>
        </div>
      </div>
      <!--  -->
      <div class="main_table">
        <el-table :data="list" v-loading="loading" style="width: 100%"
          header-cell-class-name="table-header" cell-class-name="cell-class">
          <template slot="empty">
            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
          </template>
          <el-table-column prop="name" label="角色名称"></el-table-column>
          <!-- <el-table-column prop="username" label="创建人"></el-table-column> -->
          <el-table-column label="成员数量">
            <template slot-scope="scope">
             <span class="table_span" @click="groupNumBtn(scope.row)">{{scope.row.user_count}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="添加时间"></el-table-column>
          <el-table-column prop="update_time" label="修改时间"></el-table-column>
          <el-table-column prop="switch" label="是否启用">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="addEditBtn(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="delBtn(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page_box" v-if="total" style="padding-bottom: 20px;">
        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
      </div>
    </div>
    <!-- 删除提示 -->
    <point-out ref='dialogTip' dialogTitle="提示" type="roleDel" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">您确认要删除该角色吗？</div>
      </div>
    </point-out>
	</div>
</template>

<script>
  import PointOut from '@/components/point_out/point_out.vue'//提示
	import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
	import PagingPage from '@/components/paging_page/paging_page.vue'//分页
	export default {
		components: {PointOut, EmptyData, PagingPage},
		props: {},
		data() {
			return{
				role_name:'',
        //
        list:[],
        loading:false,
        //
        page: 1,
        limit: 10,
        total:0,
        // 删除
        del_index:'',
			}
		},
		computed: {},
		watch: {},
		created() {
      this.search()
    },
		mounted() {},
		methods: {
      search() {
      	this.page = 1
      	this.list = []
      	this.total = 0
      	this.getList()
      },
      getList(){
        var that = this
        that.loading=true
        var params = {
          name:that.role_name,
          page: that.page,
          limit: that.limit,
        }
        that.$purviewApi.systemRole(params).then(res => {
          that.loading = false
          if (res.code == 1000) {
            that.list=res.result?.data
            that.total=res.result?.total
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 成员管理
      groupNumBtn(info){
        if(info.user_count){
          this.$router.push({
            path:'/role/member?id='+info.id
          })
        }
      },
      // 修改状态
      changeSwitch(val,info,index){
        var that = this
        var params = {
          id: info.id,
          status: val,
        }
        that.$purviewApi.systemRoleStatus(params).then(res => {
          if (res.code == 1000) {
            that.$succMsg(res.message)
            that.list[index].status=val
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 新增/编辑
      addEditBtn(info=''){
        if(info){
          this.$router.push({
            path:'/role/purview',
            query:{
              id:info.id,
              name:info.name
            }
          })
        }else{
          this.$router.push({
            path:'/role/purview',
          })
        }
      },
      // 删除
      delBtn(index){
        this.del_index=index
        this.$refs.dialogTip.openDialogBtn()
      },
      closeListBtn(){
        var that = this
        if(that.del_index!==''){
          that.$purviewApi.delSystemRole('',that.list[that.del_index].id).then(res => {
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.getList()
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
      // 改变每页条数
      sizeChangeBtn(val){
        this.limit = val
        this.page = 1
        this.getList()
      },
      // 改变页数
      currentChangeBtn(val){
        this.page = val
        this.getList()
      },
    },
	}
</script>

<style lang='scss' scoped>
	.role_manage{
		width: 100%;
    .role_main{
      width: 100%;
      padding: 0 20px;
      .main_condition{
        width: 100%;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cond_left{
          display: flex;
          align-items: center;
          .el-button{
            padding: 8px 10px;
            margin-right: 20px;
          }
          .el-select{
            margin-right: 20px;
            width: 160px;
          }
          .el-input{
            margin-right: 20px;
            width: 160px;
          }
        }
        .cond_right{
          .el-button{
            padding: 8px 10px;
          }
        }
      }
      .table_span{
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2E4BF2;
      }
    }
	}
</style>
